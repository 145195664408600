const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Product & Planning",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },

  {
    pages: [
      {
        sectionTitle: "Material Management",
        route: "/salesdistribution",
        svgIcon: "/media/icons/duotune/general/Purchase Management.svg",
        fontIcon: "bi-printer",
        sub: [
          
          // {
          //   heading: "Stock Journal Voucher",
          //   route: "/materialmanagement/StockJournalVoucherListing/",
          // },
          {
            heading: "Purchase Requisition",
            route: "/materialmanagement/purchaserequisitionlisting/",
          },
          // {
          //   heading: "Purchase Register",
          //   route: "/materialmanagement/purchaseregisterlisting/",
          // },
          // {
          //   heading: "Planing Indent",
          //   route: "/materialmanagement/planingindentlisting/",
          // },
          {
            heading: "Request For Quotation",
            route: "/materialmanagement/rfqlisting/",
          },
          {
            heading: "Purchase Contract",
            route: "/materialmanagement/purchaseContractlisting/",
          },
          {
            heading: "Purchase Order",
            route: "/materialmanagement/PurchaseOrderListing/",
          },
          {
            heading: "Purchase Dispatch Order",
            route: "/materialmanagement/purchasedispatchorder/",
          },
          {
            heading: "Goods Receipt Note",
            route: "/materialmanagement/goodsreceiptnotelisting/",
          },
          {
            heading: "Purchase Invoice",
            route: "/materialmanagement/purchaseinvoicelisting/",
          },
          {
            heading: "Purchase Return Note",
            route: "/materialmanagement/PurchaseReturnNoteListing/",
          },
          // {
          //   heading: "Jobwork Delivery Challan",
          //   route: "/materialmanagement/JobWorkDeliveryChallanListing/",
          // },
          // {
          //   heading: "Jobwork Process Order",
          //   route: "/materialmanagement/JobworkProcessOrderListing/",
          // },
          // {
          //   heading: "Jobwork Receipt Note",
          //   route: "/materialmanagement/JobworkReceiptNoteListing/",
          // },
          // {
          //   heading: "Job-work Invoice",
          //   route: "/materialmanagement/JobworkInvoiceListing/",
          // },
          // {
          //   heading: "Goods Issue Slip",
          //   route: "/materialmanagement/GoodsIssueSlipListing/",
          // },
          // {
          //   heading: "Goods Deposit Slip",
          //   route: "/materialmanagement/GoodsDepositSlipListing/",
          // },
          // {
          //   heading: "Invoice Slip",
          //   route: "/under-construction",
          // },
        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Sales & Distribution",
        route: "/salesdistribution",
        svgIcon: "/media/icons/duotune/technology/Sales & Distribution.svg",
        fontIcon: "bi-printer",
        sub: [
          // {
          //   heading: "Dashboard",
          //   route: "/under-construction",
          // },
          {
            heading: "Sales Enquiry",
            // route: "/salesdistribution/sales-enquiry",
            route: "/salesdistribution/sales-enquiry/",

          },
          {
            heading: "Sales Offer",
            route: "/salesDistribution/SalesOfferListing/",
          },
          {
            heading: "Sales Contract",
            route: "/salesDistribution/SalesContractListing/",
          },
          {
            heading: "Sales Order",
            route: "/salesDistribution/SalesOrderListing/",
          },
          {
            heading: "Dispatch Order",
            route: "/salesDistribution/SalesDispatchOrderListing/",
          },
          {
            heading: "Proforma Invoice",
            route: "/salesDistribution/PerformaInvoice/",
          },
          {
            heading: "Sales Invoice",
            route: "/salesDistribution/SalesInvoiceListing/",
          },
          {
            heading: "Sales Register",
            route: "/salesDistribution/SalesRegisterListing/",
          },

          // {
          //   heading: "Delivery Challan",
          //   route: "/under-construction",
          // },
          {
            heading: "Credit / Debit Note",
            route: "/salesDistribution/CreditDebitNoteListing/",
          },
          
          // {
          //   heading: "Online Cart Register",
          //   route: "/under-construction",
          // },
          // {
          //   heading: "Sales Target",
          //   route: "/under-construction",
          // },
        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Financial and Controlling",
        route: "/company",
        svgIcon: "/media/icons/duotune/finance/Financial Accounting.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Groups",
            route: "/groups",
          },
          {
            heading: "Ledgers",
            route: "/ledger",
          },
          {
            heading: "Journal Entries",
            route: "/journal",
          },
          // {
          //   heading: "Journal Types",
          //   route: "/journaltypes",
          // },
          // {
          //   heading: "Accounts",
          //   route: "/account",
          // },
          {
            heading: "Accounts Payable",
            route: "/account/AccountsPayableReport/",
          },
          {
            heading: "Accounts Receivable",
            route: "/account/AccountsReceivableReport/",
          },
          {
            heading: "Sales Ledger",
            route: "/salesledger",
          },
          {
            heading: "Purchase Ledger",
            route: "/purchaseledger",
          },
          {
            heading: "Trial Balance Ledger",
            route: "/trialbalance",
          },
          {
            heading: "Income Expense Ledger",
            route: "/incomeexpense",
          },
          {
            heading: "Assets liabilities  Ledger",
            route: "/assetsliabilities",
          },
          {
            heading: "General Ledger",
            route: "/generalledger",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "HRMS",
        route: "/hrms",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Employee List",
            route: "/employeeList",
          },
          // {
          //   heading: "Journal Types",
          //   route: "/journaltypes",
          // },
          // {
          //   heading: "Accounts",
          //   route: "/account",
          // },
          // {
          //   heading: "Journal Entries",
          //   route: "/journal",
          // },
          // {
          //   heading: "Sales Ledger",
          //   route: "/salesledger",
          // },
          // {
          //   heading: "Purchase Ledger",
          //   route: "/purchaseledger",
          // },
          // {
          //   heading: "Trial Balance Ledger",
          //   route: "/trialbalance",
          // },
          // {
          //   heading: "Income Expense Ledger",
          //   route: "/incomeexpense",
          // },
          // {
          //   heading: "Assets liabilities  Ledger",
          //   route: "/assetsliabilities",
          // },
          // {
          //   heading: "General Ledger",
          //   route: "/generalledger",
          // },
        ],
      },
    ],
  },
  // {
  //   pages: [
  //     {
  //       sectionTitle: "Human Resource",
  //       route: "/hrms",
  //       svgIcon: "/media/icons/duotune/communication/HRMS.svg",
  //       fontIcon: "bi-app-indicator",
  //       sub: [
  //         {
  //           heading: "Add Employee",
  //           route: "/employeeAdd",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    pages: [
      {
        heading: "Quality Management",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Logistics Management",
        route: "/reports",
        svgIcon: "/media/icons/duotune/general/Report.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Transport Note",
            route: "/logisticsmanagement/TransportationNoteList/",
          },

        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Marketing & Communication",
        route: "/clients",
        svgIcon: "/media/icons/duotune/communication/Marketing & Communication.svg",
        fontIcon: "bi-printer",
        sub: [
          // {
          //   heading: "Clients",
          //   route: "/clientlisting",
          // },
          // {
          //   heading: "Contacts",
          //   route: "/clients/contacts",
          // },
          // {
          //   heading: "KMP's",
          //   route: "/company/kmps",
          // },
        ],
      },
    ],
  },
  {
    pages: [
      {
        heading: "Admin & Office Management",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Maintainance & Repair",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Report & Analysis",
        route: "/reports",
        svgIcon: "/media/icons/duotune/general/Report.svg",
        fontIcon: "bi-printer",
        sub: [
          // {
          //   heading: "Purchase Ledger",
          //   route: "/purchaseledgerinvoice",
          // },
          {
            heading: "Stock Register",
            route: "/reports/StockRegisterListing/",
          },
          {
            heading: "Purchase Order Status",
            route: "/reports/purchaseOrderStatusReport/",
          },
          {
            heading: "Sales Order Status",
            route: "/reports/salesOrderStatusReport/",
          },
          // {
          //   heading: "PO Report",
          //   route: "/materialmanagement/poStaticTemplate/",
          // },
          {
            heading: "Purchase Ledger",
            route: "/materialmanagement/purchaseledgerReport/",
          },

          // {
          //   heading: "Sales Ledger",
          //   route: "/SalesLedgerInvoice",
          // },

          {
            heading: "Sales Ledger",
            route: "/materialmanagement/SalesLedgerReport/",
          },
          {
            heading: "General Ledger",
            route: "/reports/generalLedger",
          },
          // {
          //   heading: "Debit Note",
          //   route: "/DebitNote",
          // },
          {
            heading: "Debit Note",
            route: "/materialmanagement/DebitNoteReport/",
          },

          // {
          //   heading: "Payment Receivables",
          //   route: "/PaymentReceivables",
          // },

          {
            heading: "Payment Receivables",
            route: "/materialmanagement/PaymentReceivablesReport/",
          },

          // {
          //   heading: "Ammount Payable",
          //   route: "/AmountReceivables",
          // },

          {
            heading: "Amount Payable",
            route: "/materialmanagement/AmountPayablesReport/",
          },

          {
            heading: "Godown",
            route: "/materialmanagement/GodownReport/",
          },
          {
            heading: "Purchase Register",
            route: "/materialmanagement/purchaseregisterlisting/",
          },

        ],
      },
    ],
  },
  {
    pages: [
      {
        heading: "Governament & Compliances",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Control Panel",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/general/Setting.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "IT & Support",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "E-Commerce",
        route: "/under-construction",
        svgIcon: "/media/icons/duotune/communication/HRMS.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Research",
        route: "/research",
        svgIcon: "/media/icons/duotune/finance/Financial Accounting.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          // {
          //   heading: "Price Template",
          //   route: "/research/price",
          // },
          {
            heading: "Price Collection ",
            route: "/research/price-collector",
          },
          {
            heading: " Price Upload",
            route: "/research/price-upload",
          },
          
        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Master",
        route: "/product",
        svgIcon: "/media/icons/duotune/finance/Product & Management.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Entity Profile",
            route: "/entity-profile",
          },
          {
            heading: "Upload Entity Data",
            route: "/entity-upload",
          },
          // {
          //   heading: "Upload Price Data",
          //   route: "/price-upload",
          // },
          // {
          //   heading: "Price Collector ",
          //   route: "/price/price-collector-upload",
          // },
          {
            heading: "Categories",
            route: "/products/categories",
          },
          {
            heading: "Products",
            route: "/products",
          },
          {
            heading: "Product Standard",
            route: "/products/product-standard",
          },
          // {
          //   heading: "Product Grade",
          //   route: "/products/product-grade",
          // },
          {
            heading: "Product Hsn",
            route: "/products/product-hsn",
          },
          // {
          //   heading: "Product Attribute",
          //   route: "/products/product-attribute",
          // },
          // {
          //   heading: "Product Brand",
          //   route: "/products/product-brand",
          // },
          //  {
          //   heading: "Product Prices",
          //   route: "/products/product-prices",
          //  },
        ],
      },
      // {
      //   sectionTitle: "Manage KMP",
      //   route: "/company/kmps",
      //   svgIcon: "/media/icons/duotune/communication/com014.svg",
      //   fontIcon: "bi-printer",
      //   sub: [
      //     {
      //       heading: "KMP's",
      //       route: "/company/kmps",
      //     }
      //   ],
      // }
    ],
  },
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/Dashboard.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //     {
  //       heading: "Profile",
  //       route: "/profiledetail",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },








  // {
  //   pages: [
  //     {
  //       heading: "Material Management",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/general/gen019.svg",
  //       fontIcon: "bi-layers",
  //     },
  //   ],
  // },
  // {
  //   pages: [
  //     {
  //       heading: "Sales & Distribution",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/technology/teh004.svg",)
  //       fontIcon: "bi-sticky",
  //     },
  //   ],
  // },





  // {
  //   pages: [
  //     {
  //       heading: "Settings",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/general/Setting.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
  // {
  //   pages: [
  //     {
  //       heading: "Quality Management",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/art/art009.svg",
  //       fontIcon: "bi-shield-check",
  //     },
  //   ],
  // },
  // {
  //   pages: [
  //     {
  //       heading: "Maintenance & Repair",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/general/gen025.svg",
  //       fontIcon: "bi-layers",
  //     },
  //   ],
  // },
  // {
  //   pages: [
  //     {
  //       heading: "Government & Compliance",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/finance/fin006.svg",
  //       fontIcon: "bi-printer",
  //     },
  //   ],
  // },

  // {
  //   pages: [
  //     {
  //       heading: "Cost Accounting",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/general/gen014.svg",
  //       fontIcon: "bi-calendar3-event",
  //     },
  //   ],
  // },


  // {
  //   pages: [
  //     {
  //       heading: "Admin",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/technology/teh004.svg",
  //       fontIcon: "bi-sticky",
  //     }
  //   ],
  // },
];

export default DocMenuConfig;
