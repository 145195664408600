
import { defineComponent } from "vue";
// import KTSearch from "@/layout/header/partials/Search.vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "topbar",
  components: {
    // KTSearch,
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
});
